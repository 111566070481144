import { computed, ref, SetupContext, watch } from '@vue/composition-api'

import { ComponentObjectPropsOptions, Emit } from '../../vue-api'
import { useSrcSet } from '../../composables/src-set'

import { FALLBACK_IMG_BASE64 } from './Image.config'
import { ImageFit, imageFitOptions, ImageProps, UseImageProvides } from './Image.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (edited)
 */
export const imageProps: ComponentObjectPropsOptions<ImageProps> = {
  alt: {
    type: String,
    required: false,
    default: ''
  },

  fallback: {
    type: String,
    required: false
  },

  fit: {
    type: String,
    required: false,
    default: 'cover'
  },

  isLazy: {
    type: Boolean,
    required: false,
    default: true
  },

  src: {
    type: String,
    required: true
  },

  srcset: {
    type: Object,
    required: false
  },

  title: {
    type: String,
    required: false,
    default: ''
  }
}

/**
 * @param props
 * @param emit
 * @param [loadCallback?] - Callback function that will be called
 *  when the image file has been successfully loaded.
 * @param [errorCallback?] - Callback function that will be called
 *  when the image file fails to load.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export const useImage = (
  props: ImageProps,
  emit: Emit,
  loadCallback?: () => unknown,
  errorCallback?: () => unknown,
  ctx?: SetupContext
): UseImageProvides => {
  const { sizes, _srcset } = useSrcSet(props)

  /**
   * Determines whether the `<img>` element has been successfully loaded.
   */
  const isLoaded = ref(false)

  /**
   * The value for the `[alt]` attribute.
   */
  const imgAlt = ref(props.alt)

  /**
   * The value for the `[src]` attribute.
   */
  const imgSrc = ref(props.src)

  /**
   * Image fallback
   */
  const fallbackImage = computed<string>(() => typeof props.fallback === 'string' ? props.fallback
    : FALLBACK_IMG_BASE64)

  /**
   * Determines the css class that should be applied to image element.
   */
  const imgFitClass = computed<string>(() => {
    if (typeof props.fit === 'undefined') {
      return imageFitOptions[ImageFit.Cover]
    }

    if (!(props.fit in imageFitOptions)) {
      console.warn(`Incorrect object-fit option for given 'fit' prop! Got [${props.fit}]\nResetting to default value...`)
      return imageFitOptions[ImageFit.Cover]
    }

    return imageFitOptions[props.fit]
  })

  const shouldBeLazy = computed<boolean>(() => {
    if (typeof props.isLazy === 'undefined') {
      return false
    }

    return props.isLazy && !isLoaded.value
  })

  /**
   * Handles the @error event on the `<img>` element.
   */
  const onError = () => {
    emit('error')

    if (!props.isLazy) {
      showFallbackImage()
    }

    if (typeof errorCallback === 'function') {
      errorCallback()
    }
  }

  /**
   * Handles the @load event on the `<img>` element.
   */
  const onLoad = () => {
    if (isLoaded.value === true) {
      return
    }

    isLoaded.value = true

    emit('loaded')

    if (typeof loadCallback === 'function') {
      loadCallback()
    }
  }

  /**
   * Renders the fallback image (in case of the load error).
   */
  const showFallbackImage = () => {
    imgAlt.value = ''
    imgSrc.value = fallbackImage.value
  }

  /**
   * Determines if props.src has changed.
   */
  watch<string>(() => props.src, (value, oldValue) => {
    if (value === oldValue) {
      return
    }

    imgSrc.value = value
  })

  ctx?.root.$Lazyload.$on('loaded', (event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (imgSrc.value === event.src && event.state.loaded) {
      onLoad()
    }
  })

  return { fallbackImage, imgAlt, imgSrc, isLoaded, imgFitClass, shouldBeLazy, sizes, _srcset, onError, onLoad }
}
